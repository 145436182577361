<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Current Category: </span>
      <div v-text="currentCate"></div>
      <el-button class="btn-add" @click="handleAddProductCate()" size="mini">
        添加
      </el-button>
    </el-card>
    <div class="table-container">
      <el-table
        ref="productCateTable"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Name" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="Depth" width="100" align="center">
          <template slot-scope="scope">{{
            scope.row.depth | levelFilter
          }}</template>
        </el-table-column>
        <el-table-column
          label="Subcategories Count"
          align="center"
          v-if="isParent"
        >
          <template slot-scope="scope">{{
            scope.row.children.length
          }}</template>
        </el-table-column>
        <el-table-column label="Sku Section" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.skuSection }}</template>
        </el-table-column>
        <el-table-column label="Type"  width="100" align="center">
          <template slot-scope="scope">{{scope.row.categoryType}} </template>
        </el-table-column>
        <el-table-column label="设置" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              :disabled="scope.row.level"
              v-if="isParent"
              @click="handleShowNextLevel(scope.$index, scope.row)"
              >查看下级
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleUpdate(scope.$index, scope.row)"
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes,prev, pager, next,jumper"
        :page-size="listQuery.pageSize"
        :page-sizes="[5, 10, 15]"
        :current-page.sync="listQuery.pageNum"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  fetchList,
  deleteProductCate,
  getProductCate,
  fetchListByParentId,
} from '@/api/productCate';

export default {
  name: 'productCateList',
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 5,
      },
      parentId: 0,
      currentCate: '[Root]',
      isParent: null,
    };
  },
  created() {
    this.resetParentId();
    this.getList();
    this.getCurrentCate();
    if (this.$route.query.parentId != null) this.isParent = false;
    else this.isParent = true;
  },
  watch: {
    $route(route) {
      this.resetParentId();
      this.getList();
      this.getCurrentCate();
      if (this.$route.query.parentId != null) this.isParent = false;
      else this.isParent = true;
    },
  },
  methods: {
    getCurrentCate() {
      if (this.$route.query.parentId != null) {
        getProductCate(this.$route.query.parentId).then((response) => {
          this.currentCate = response.data.name;
        });
      }
    },
    resetParentId() {
      this.listQuery.pageNum = 1;
      if (this.$route.query.parentId != null) {
        this.parentId = this.$route.query.parentId;
      } else {
        this.parentId = 0;
      }
    },
    handleAddProductCate() {
      this.$router.push('/productService/addProductCate');
    },
    getList() {
      this.listLoading = true;
      if (this.$route.query.parentId != null) {
        fetchListByParentId(this.$route.query.parentId).then((response) => {
          this.listLoading = false;
          this.list = response.data.data;
          this.total = response.data.total;
          this.list.sort((x, y) => x.name.localeCompare(y.name));
        });
      } else {
        fetchList(this.parentId, this.listQuery).then((response) => {
          this.listLoading = false;
          this.list = response.data.data;
          this.total = response.data.total;
          this.list.sort((x, y) => x.name.localeCompare(y.name));
        });
      }
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    handleShowNextLevel(index, row) {
      this.$router.push({
        path: '/productService/productCate',
        query: { parentId: row.id },
      });
    },
    handleTransferProduct(index, row) {},
    handleUpdate(index, row) {
      this.$router.push({
        path: '/productService/updateProductCate',
        query: { id: row.id },
      });
    },
    handleDelete(index, row) {
      this.$confirm('Are you sure to delete this product category?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteProductCate(row.id).then((response) => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
  },
  filters: {
    levelFilter(value) {
      if (value === 0) {
        return 'Parent';
      }
      if (value === 1) {
        return 'Child';
      }
    },
    disableNextLevel(value) {
      if (value === 0) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
</style>
